<template>
  <div class="page-content" v-if="newbieMission">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Newbie Mission"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Newbie Mission</h2>
      <b-card class="mt-1">
        <validation-observer
          ref="updateForm"
          v-slot="{ handleSubmit }"
          class="mr-4 ml-4"
        >
          <b-form @submit.stop.prevent="handleSubmit()">
            <b-form-group
              label="Mission Name"
              label-for="h-mission-name"
              label-cols-md="3"
            >
              <ui-component
                :component-data="newbieMission.name"
                class="border"
              />
            </b-form-group>
            <b-form-group
              label="Mission Description"
              label-for="h-mission-description"
              label-cols-md="3"
            >
              <ui-component
                :component-data="newbieMission.description"
                class="border"
              />
            </b-form-group>
            <b-form-group
              label="Ordinal Of Week"
              label-for="h-category"
              label-cols-md="3"
            >
              <b-form-select
                v-model="newbieMission.ordinal_of_week"
                :options="ordinals"
              />
            </b-form-group>
            <b-form-group
              label="Category"
              label-for="h-category"
              label-cols-md="3"
            >
              <b-form-select
                v-model="newbieMission.category"
                :options="categories"
              />
            </b-form-group>
            <b-form-group
              label="Lesson"
              label-for="h-category"
              label-cols-md="3"
            >
              <multi-select :multiple="false" id="lesson_select" v-model="newbieMission.lesson_id" :options="lessons"
                @search-change="lessonSearch"
              />
            </b-form-group>
            <b-form-group
              v-if="newbieMission.category && newbieMission.category !== 'arena'"
              label="Lesson step"
              label-for="h-category"
              label-cols-md="3"
            >
              <b-form-select
                v-model="newbieMission.lesson_step"
                :options="lessonSteps"
              />
            </b-form-group>
            <b-form-group v-else
              label="Level"
              label-cols-md="3"
            >
              <b-form-select
                v-model="newbieMission.user_level"
                :options="user_levels"
              />
            </b-form-group>
            <b-form-group
              label="Mission key"
              label-for="h-reward"
              label-cols-md="3"
            >
              <b-form-input v-model="newbieMission.mission_key" />
            </b-form-group>
            <div v-for="(item, index) in newbieMission.rewards" :key="index">
              <validation-provider
                name="Reward type"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Reward Type"
                  label-for="h-reward"
                  label-cols-md="3"
                >
                  <b-form-select
                    v-model="item.type"
                    :options="missionRewards"
                    :state="getValidationState(validationContext)"
                  />
                  <b-form-invalid-feedback id="input-category-name-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <validation-provider
                name="Amount"
                v-slot="validationContext"
                :rules="{ required: true }"
              >
                <b-form-group
                  label="Amount"
                  label-for="h-amount"
                  label-cols-md="3"
                >
                  <b-form-input
                    v-model="item.amount"
                    :state="getValidationState(validationContext)"
                    placeholder="Amount"
                  />
                  <b-form-invalid-feedback id="input-category-name-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      @update="update"
      :is-updating="isUpdating"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import { mapGetters, mapMutations } from 'vuex'
import missionRewardService from "./../../mission_reward/service";
import pronunLessonService from "../../../pronun/service";
import breakingLessonService from "../../../breaking_lesson/service";
import arenaStagesService from "../../../arena_v2/stage/service";
import { categories, selectRewards, ordinals, user_levels } from "./../constants";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      newbieMission: null,
      lessons: [],
      lessonSteps: [],
      categories,
      missionRewards: [],
      ordinals,
      lesson_search: null,
      user_levels,
    };
  },
  computed: {
    ...mapGetters('newbieMission', [
      'arenaStages',
      'pronunLessons',
      'breakingLessons',
    ]),
    newbieMissionId() {
      return this.$route.params.mission_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Newbie Mission List",
          to: { name: "mission-newbie-list" },
        },
      ];
      items.push({ text: "Newbie Mission Detail", active: true });
      return items;
    },
  },
  watch: {
    "newbieMission.category": function (newValue) {
      this.lessons = [];
      if (newValue) {
        this.getLessons(newValue);
      }
    },

    "newbieMission.lesson_id": function (newValue) {
      this.lessonSteps = [];
      if (newValue) {
        this.prepareLessonSteps(newValue);
      }
    },
  },

  async created() {
    this.getNewbieMission();
    this.getMissionRewards();
  },

  methods: {
    ...mapMutations('newbieMission', [
      'setArenaStages',
      'setPronunLessons',
      'setBreakingLessons',
    ]),

    async getMissionRewards() {
      const { data } = await missionRewardService.getAll();
      if (data && data.data.list && data.data.list.length) {
        this.missionRewards = data.data.list.map((reward) => {
          return {
            text: reward.type,
            value: reward.type,
          };
        });
        return;
      }

      this.missionRewards = selectRewards;
    },
    lessonSearch(key) {
      this.lesson_search = Number(key);
      if(this.newbieMission.category === 'arena') {
        this.getLessons();
      }
    },
    async getLessons() {
      let category = this.newbieMission.category;
      if (!category) {
        return;
      }
      switch (category) {
        case "arena":
          // if (this.arenaStages.length) {
          //   this.lessons = JSON.parse(JSON.stringify(this.arenaStages));
          //   return;
          // }
          await this.getArenaStages();
          break;

        case "pronun":
          if (this.pronunLessons.length) {
            this.lessons = JSON.parse(JSON.stringify(this.pronunLessons));
            return;
          }
          await this.getPronunLessons();
          break;

        case "breaking":
          if (this.breakingLessons.length) {
            this.lessons = JSON.parse(JSON.stringify(this.breakingLessons));
            return;
          }
          await this.getBreakingLessons();
          break;
      }
    },

    async prepareLessonSteps(lesson) {
      if (!this.newbieMission.category) {
        return;
      }
      switch (this.newbieMission.category) {
        case "pronun":
          if (!this.pronunLessons.length) {
            await this.getPronunLessons();
          }
          this.getLessonSteps(lesson, this.pronunLessons);
          break;

        case "breaking":
          if (!this.breakingLessons.length) {
            await this.getBreakingLessons();
          }
          this.getLessonSteps(lesson, this.breakingLessons);
          break;
      }
    },

    async getArenaStages() {
      let query = { is_published: true };
      if(this.lesson_search && Number.isInteger(this.lesson_search)) {
        query.stage_number = this.lesson_search - 1;
      } else {
        if(this.newbieMission.lesson_id) {
          query._id = this.newbieMission.lesson_id;
        }
      }
      const response = await arenaStagesService.getList({
        query,
        page :1,
        limit: 60
      });
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: "Stage " + (item.stage_number + 1),
          };
        });
      }
      this.setArenaStages(JSON.parse(JSON.stringify(this.lessons)))
    },

    async getPronunLessons() {
      const response = await pronunLessonService.getAll({
        order_by: "display_order",
        order: 1,
      });
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: item.display_order
              ? `${item.display_order + 1}: ` + item.title
              : item.title,
            steps: item.steps,
          };
        });
      }
      this.setPronunLessons(JSON.parse(JSON.stringify(this.lessons)))
    },

    async getBreakingLessons() {
      const response = await breakingLessonService.getAll({
        order_by: "display_order",
        order: 1,
      });
      if (response) {
        this.lessons = response.list.map((item) => {
          return {
            value: item._id,
            text: item.display_order
              ? `${item.display_order + 1}: ` + item.title
              : item.title,
            steps: item.steps,
          };
        });
      }
      this.setBreakingLessons(JSON.parse(JSON.stringify(this.lessons)))
    },

    getLessonSteps(lesson, lessons) {
      const lessonFound = lessons.find((item) => item.value === lesson);
      if (!lessonFound || !lessonFound.steps.length) {
        this.lessonSteps = [];
        return;
      }
      this.lessonSteps = lessonFound.steps.map((step) => {
        return {
          text: step.name,
          value: step.key,
        };
      });
    },

    async getNewbieMission() {
      this.newbieMission = await service.get({ id: this.newbieMissionId });
    },

    async update() {
      let validation = await this.$refs.updateForm.validate();
      if (validation) {
        this.isUpdating = true;
        await service.update({
          data: JSON.stringify(this.newbieMission),
        });
        this.isUpdating = false;
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
